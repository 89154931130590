import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BlogLayout from '../components/BlogLayout';
import Seo from '../components/seo';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

const BlogPost = styled(Grid)`
  max-width: 500px !important;
`;

const GridContainer = styled(Grid)``;

const ImageContainer = styled(Link)`
  display: block;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: transform 300ms;
  transform-origin: 50% 50%;

  &:hover {
    transform: scale(1.02);
  }
`;

const PostTitle = styled.h2`
  margin-bottom: 0.2916666667em;
  margin-top: 0;

  .headline {
    font-size: 1.375rem;
    line-height: 1.33333125rem;
    font-weight: 500;
    color: #212326;

    &:hover {
      text-decoration: underline;
    }
  }
`;

/*const CategoryTag = styled.span`
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.5;
  margin-bottom: 0.3846153846em;
  color: rgb(56, 113, 241, 0.8);
`;*/

const PostingDate = styled.small`
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #6b7177;
`;

const ArticleMeta = styled.ul`
  margin-bottom: 0.3846153846em;
  font-size: 0.875em;
  line-height: 1.5;

  li {
    position: relative;
    display: inline-block;
    margin-right: 16px;
  }

  .authorItem:after {
    content: '';
    position: absolute;
    right: -16px;
    top: 50%;
    margin: -1px 5px 0;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #d2d5d9;
  }
`;

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  return (
    <BlogLayout location={location} title={siteTitle}>
      <Seo
        title="Blog"
        description="Insights on design and conversion optimization"
        pathName={location.pathname}
      />
      <GridContainer
        container
        spacing={5}
        justifyContent="center"
        alignItems="flex-start"
      >
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug;

          return (
            <BlogPost item key={post.fields.slug} md={6} sm={12}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <ImageContainer to={post.fields.slug} itemProp="url">
                  <ThumbnailImage
                    src={post.frontmatter.thumbnail}
                    alt={post.frontmatter.title + '- Featured Shot'}
                  />
                </ImageContainer>
                <header>
                  {/*post.frontmatter.category && (
                    <CategoryTag>{post.frontmatter.category}</CategoryTag>
                  )*/}
                  <PostTitle>
                    <Link to={post.fields.slug} itemProp="url">
                      <span className="headline" itemProp="headline">
                        {title}
                      </span>
                    </Link>
                  </PostTitle>
                  <ArticleMeta>
                    <li className="authorItem">
                      <span>{post.frontmatter.author}</span>
                    </li>
                    <li>
                      <PostingDate itemProp="datePublished">
                        {post.frontmatter.date}
                      </PostingDate>
                    </li>
                  </ArticleMeta>
                </header>
              </article>
            </BlogPost>
          );
        })}
      </GridContainer>
    </BlogLayout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail
          category
          author
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { useIntl } from 'gatsby-plugin-react-intl';
import CTAButton from '../../../components/CTAButton';
import { makeStyles, Box as MuiBox } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actionButton: {
    margin: '0.5rem',
  },
}));

const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box {...sectionWrapper}>
      <Container
        mobileGutter={true}
        style={{
          backgroundColor: '#081d34',
          borderRadius: '8px',
        }}
      >
        <Box {...row}>
          <Box {...textArea}>
            <Heading
              {...title}
              content={intl.formatMessage({ id: 'landing.test_title' })}
            />
            <Text
              {...description}
              content={intl.formatMessage({ id: 'landing.test_text' })}
            />
            <MuiBox display="flex" justifyContent="center">
              <CTAButton
                className={classes.actionButton}
                fontSize="1rem"
                height="3rem"
              />
            </MuiBox>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['0px', '0px', '0px', '40px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textArea: {
    width: ['100%', '100%', '80%', '43%'],
    pt: ['30px'],
    pb: ['30px'],
  },
  imageArea: {
    width: ['100%', '100%', '43%'],
    mb: ['35px', '35px', '40px', '40px'],
  },
  title: {
    fontSize: ['28px', '32px', '36px', '42px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '24px',
    textAlign: 'center',
    lineHeight: '1.2',
  },
  description: {
    letterSpacing: '0.02em',
    lineHeight: '1.4',
    textAlign: 'center',
    mb: ['28px'],
    fontWeight: '300',
    color: '#fff',
  },
  ImageOne: {
    ml: 'auto',
    mr: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'secondaryWithBg',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#081d34',
    colors: 'secondary',
  },
};

export default TrialSection;

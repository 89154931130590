import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/src/theme/saasModern';
import Navbar from '../containers/SaasModern/Navbar';
import Footer from '../containers/SaasModern/Footer';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';
import { ResetCSS } from 'common/src/assets/css/style';
import TrialSection from '../containers/SaasModern/Trial';

const SectionWrapperElement = styled.div`
  margin: 3rem 0 5rem 0;
  padding: 50px 0;
`;

const BlogLayout = ({ secHeading, secTextFirst, children }) => {
  const [navbarFixed, setNavbarFixed] = useState(false);

  return (
    <ThemeProvider theme={saasModernTheme}>
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky
          top={0}
          innerZ={9999}
          activeClass="sticky-nav-active"
          onStateChange={status =>
            setNavbarFixed(status.status === Sticky.STATUS_FIXED)
          }
        >
          <DrawerProvider>
            <Navbar fixed={navbarFixed} />
          </DrawerProvider>
        </Sticky>
        <Container>
          <Box style={{ paddingTop: '12rem' }}>
            <Heading {...secHeading} content="The Clueify Blog" as="h1" />
            <Text
              {...secTextFirst}
              content="Insights on design and conversion optimization."
              as="h2"
            />
          </Box>
        </Container>
        <SectionWrapperElement>
          <Container>{children}</Container>
        </SectionWrapperElement>
        <TrialSection />
        <Footer showLanguageSection={false} />
      </ContentWrapper>
    </ThemeProvider>
  );
};

BlogLayout.defaultProps = {
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secTextFirst: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '300',
    mb: '0px',
    mt: '12px',
    lineHeight: '1.7',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['26px', '28px'],
    fontWeight: '700',
    color: '#081d34',
    mb: '0',
    lineHeight: '1.2',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
};

export default BlogLayout;
